import VerificationModule from "../modules/verification";
import AccountsModule from "../modules/accounts";
import IBModule from "../modules/ib";
import SalesModule from "../modules/sales";
import WalletModule from "../modules/wallet";
import ToolsModule from "../modules/tools";
import SupportsModule from "../modules/supports";
import RepModule from "../modules/rep";
import EventShopModule from "../modules/eventshop";

export default {
  openModules: [
    VerificationModule,
    AccountsModule,
    IBModule,
    SalesModule,
    WalletModule,
    ToolsModule,
    SupportsModule,
    RepModule,
    EventShopModule,
    // ShopModule,
  ],
};
